import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';

import stylesheet from '@/tailwind.css?url';
import tldrawStylesheet from '@/tldraw.css?url';
import { LinksFunction } from '@remix-run/node';
import { Suspense } from 'react';
import { ConstructableLogo } from './components/constructable-logo';
import { DefaultErrorBoundary } from './components/default-error-boundary';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'stylesheet', href: tldrawStylesheet },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap',
  },
  { rel: 'icon', href: '/logos/logo.ico', sizes: 'any' },
  { rel: 'icon', href: '/logos/logo.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/logos/logo-512.png', sizes: '512x512' },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        ></meta>
        <Meta />
        <Links />
      </head>
      <body className="h-[100dvh] w-[100dwh] overflow-hidden">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const HydrateFallback = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <div
        className="animate-pulse"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        <div className="animate-pulse">
          <ConstructableLogo />
        </div>
        <div className="text-sm text-muted-foreground">Constructable</div>
      </div>
    </div>
  );
};

export const ErrorBoundary = DefaultErrorBoundary;

function App() {
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
}

export default withSentry(App);
